<template>
  <div>
    <el-card>
      <div class="card-header">许可帐号使用概况</div>
      <div class="flex flex-justify-between" style="margin-top: 24px;" v-loading="infoloading">
        <div class="con-box" style="margin-right: 40px;">
          <div class="con-header">基础帐号</div>
          <div class="con-content">
            <div class="demo-box">
              <div class="number">{{info.BuyBaseAccountCount}}</div>
              <div class="font">已购买许可数</div>
            </div>
            <div class="demo-box">
              <div class="number">{{info.UsedBaseAccountCount}}</div>
              <div class="font">已使用许可数</div>
            </div>
            <div class="demo-box">
              <div class="number">{{info.BuyBaseAccountCount-info.UsedBaseAccountCount}}</div>
              <div class="font">剩余可用许可数</div>
            </div>
          </div>
        </div>
        <div class="con-box">
          <div class="con-header">互通帐号</div>
          <div class="con-content">
            <div class="demo-box">
              <div class="number">{{info.BuyContactAccountCount}}</div>
              <div class="font">已购买许可数</div>
            </div>
            <div class="demo-box">
              <div class="number">{{info.UsedContactAccountCount}}</div>
              <div class="font">已使用许可数</div>
            </div>
            <div class="demo-box">
              <div class="number">{{info.BuyContactAccountCount-info.UsedContactAccountCount}}</div>
              <div class="font">剩余可用许可数</div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <el-card style="margin-top:10px">
      <div class="card-header">许可帐号使用明细</div>
      <div class="flex flex-align-center" style="margin:24px 0px 10px 0px">
        <div class="flex flex-align-center" style="margin-right:10px">
          <div style="color:#606266;font-size:14px;font-weight:bold">关键字：</div>
          <el-input v-model="keywords" style="width:200px;" placeholder="员工姓名、登录帐号"></el-input>
        </div>
        <el-button type="primary" @click="query" style="margin-right:10px">查询</el-button>
        <buttonPermissions :datas="'Interfacelicensingactivation'">
          <el-button type="primary" style="" @click="activationShow=true">激活许可帐号</el-button>
        </buttonPermissions>
      </div>
      <el-table :data="tablelist" v-loading="tableloading">
        <el-table-column prop="" label="员工">
          <template slot-scope="scope">
            <div class="ellipsis">
              {{scope.row.EmployeeName||'--'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="登录帐号(手机号)">
          <template slot-scope="scope">
            <div>
              {{scope.row.Phone||'--'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="BaseAccountStateValue" label="基础帐号"></el-table-column>
        <el-table-column prop="ContactAcountStateValue" label="互通帐号"></el-table-column>
        <el-table-column prop="" label="操作" width="150">
          <template slot-scope="scope">
            <div>
              <buttonPermissions :datas="'Interfacelicensingextend'">
                <el-button type="text" @click="toinherit(scope.row)">继承许可帐号</el-button>
              </buttonPermissions>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top:10px;text-align:right" v-if="total">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>

    <el-dialog :visible.sync="activationShow" title="激活许可帐号" class="dialog-body-paddingTop-10" width="800px" @closed="close">
      <div class="form">
        <div class="flex flex-align-center">
          <div class="form-label label-star" style="width:120px;margin-top:0px">激活帐号类型：</div>
          <el-radio-group v-model="activationmsg.LicenseAccountType">
            <el-radio :label="1">
              <span>基础帐号
                <span style="color:#999999FF">（余：{{info.BuyBaseAccountCount-info.UsedBaseAccountCount}}个）</span>
              </span>
            </el-radio>
            <el-radio :label="2">
              <span>互通帐号
                <span style="color:#999999FF">（余：{{info.BuyContactAccountCount-info.UsedContactAccountCount}}个）</span>
              </span>
            </el-radio>
          </el-radio-group>
        </div>
        <div class="flex" style="margin-top:20px">
          <div class="form-label label-star" style="width:120px;margin-top:7px">激活员工：</div>
          <div>
            <div class="flex flex-align-center">
              <el-input v-model.trim="searchkey" style="width:200px;margin-right:10px" placeholder=""></el-input>
              <el-button type="primary" @click="search">查询</el-button>
            </div>
            <div class="grayfont" style="margin-top:5px">输入员工手机号查找；可查找多个数据，批量激活</div>
            <div class="flex flex-align-center defaultbox" v-loading="searchloading">
              <div class="customerBox" style="" v-for="(v,i) in managementCustomer" :key="i">
                <div class="imgbox">
                  <img :src="v.WxHeadUrl?v.WxHeadUrl:defaultheader" style="border-radius:5px;width:100%" alt="">
                  <div class="closeIcon" @click="clear(i)">
                    <i class="el-icon-error"></i>
                  </div>
                </div>
                <div class="ellipsis" style="width:100%;text-align:center">{{v.EmployeeName||'--'}}</div>
                <div class="phone">{{v.Phone}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top:10px;text-align:right">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" :loading="managementLoading" @click="confirmactivation">确认</el-button>
      </div>
    </el-dialog>


    <el-dialog :visible.sync="errShow" title="激活帐号失败列表" @closed="closeerr" width="800px" :show-close="false">
      <div class="grayfont" style="font-size:14px">以下员工帐号激活失败：</div>
      <el-table :data="errlist" style="margin-top:10px" max-height="500px" border>
        <el-table-column prop="" label="员工">
          <template slot-scope="scope">
            <div class="flex flex-align-center">
              <img :src="scope.row.WxHeadUrl||defaultheader" style="width:48px;height:48px;border-radius:3px;margin-right:5px" alt="">
              <div>
                <div>{{scope.row.EmployeeName||'--'}}<span v-if="scope.row.WxNickname">({{scope.row.WxNickname}})</span></div>
                <div>{{scope.row.Phone}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="message" label="失败原因"></el-table-column>
      </el-table>
      <div style="margin-top:10px;text-align:right">
        <el-button @click="closeerr">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="inheritShow" title="继承许可帐号" @closed="closeinherit" width="650px">
      <div class="flex flex-align-center" style="">
        <div class="grayfont" style="width:120px;text-align:right;font-size:14px">可继承员工：</div>
        <div class="grayfont" style="font-size:14px">{{rowmsg.EmployeeName||'--'}}({{rowmsg.Phone||'--'}})</div>
      </div>
      <div class="form" style="margin-top:20px">
        <div class="flex flex-align-center">
          <div class="form-label label-star" style="width:120px;margin-top:0px">接收继承员工：</div>
          <el-select v-model="acceptEmployees" placeholder="输入员工手机号查找" style="width:300px" filterable popper-class="popper-max500">
            <el-option v-for="(v,i) in acceptlist" :key="i" :value="v.Id" :label="(v.EmployeeName||'--')+'('+(v.Phone||'--')+')'"></el-option>
          </el-select>
        </div>
      </div>
      <div style="margin-top:20px;text-align:right">
        <el-button @click="closeinherit">取消</el-button>
        <el-button type="primary" :loading="inheritanceloading" @click="confirminheritance">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import buttonPermissions from '@/components/buttonPermissions';
import {
  qyweixinlicenseuseinfo,
  qyweixinlicenseusedetail,
  qyweixinactiveaccount,
  qyweixintransferlicenseemployee,
  qyweixinlicensetransfer,
  employeeemployeeinfobyphone
} from "@/api/sv3.0.0"
import config from "@/config"
import {
  memberinfobyMemberId,
  SearchLockEmployee
} from "@/api/TurnTomySelf"
import {
  qyweixinsyncqyweixinlicensedata,
  qyweixinsyncqyweixinlicenseexpiretime
}from "@/api/sv3.0.0"
import { mapGetters } from 'vuex';
export default {
  components: {
    buttonPermissions
  },
  computed: {
    ...mapGetters([
				"MallId"
			]),
  },
  data () {
    return {
      infoloading:false,
      info:{},
      keywords:'',
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pageSize:20,
      total:0,

      activationShow:false,
      activationmsg:{
        LicenseAccountType:0,
        EmployeeId:''
      },
      defaultheader:config.DEFAULT_HEADER,
      searchloading:false,
      searchkey:'',
      managementCustomer:[],
      managementLoading:false,
      errlist:[],
      errShow:false,

      inheritShow:false,
      rowmsg:{},
      acceptEmployees:'',
      acceptlist:[],
      inheritanceloading:false,
      
    }
  },
  mounted () {
    this.getinfo()
    this.gettablelist()
  },
  methods: {
    async synchronization(){
      let MallId = window.localStorage.getItem('MallId')
      try{
        qyweixinsyncqyweixinlicensedata({
          MallId:MallId
        })
        qyweixinsyncqyweixinlicenseexpiretime({
          MallId:MallId
        })
      }finally{
        //
      }
    },
    async query(){
      try{
        this.synchronization()
        this.currentPage = 1
        this.gettablelist()
      }finally{
        //
      }
    },
    closeerr(){
      this.errShow=false
      this.errlist=[]
      this.activationShow=false
    },
    closeinherit(){
      this.inheritShow=false
      this.activationShow=false
      this.acceptEmployees=''
    },
    async confirminheritance(){
      if(!this.acceptEmployees){
        this.$message.error('请选择被继承员工')
        return
      }
      try{
        this.inheritanceloading = true
        let res = await qyweixinlicensetransfer({
          HandoverEmployeeId:this.rowmsg.Id,
          TakeoverEmployeeId:this.acceptEmployees
        })
        if(res.IsSuccess){
          this.$message.success('继承成功')
          this.closeinherit()
          this.gettablelist()
        }
      }finally{
        this.inheritanceloading = false
      }
    },
    async getacceptlist(){
      try{
        let res = await qyweixintransferlicenseemployee()
        if(res.IsSuccess){
          this.acceptlist = res.Result
        }
      }finally{
        //
      }
    },
    toinherit(row){
      if((row.BaseAccountState==0||row.BaseAccountState==2)&&(row.ContactAcountState==0||row.ContactAcountState==2)){
        this.$message.error('员工无可继承帐号')
        return
      }
      this.rowmsg = row
      this.inheritShow = true
      this.getacceptlist()
    },
    async confirmactivation(){
      if(!this.activationmsg.LicenseAccountType){
        this.$message.error('请选择激活帐号类型')
        return
      }else if(!this.managementCustomer||!this.managementCustomer.length){
        this.$message.error('请输入手机号，查询并添加需要发送邀请的员工')
        return
      }else if(this.activationmsg.LicenseAccountType==1&&this.managementCustomer.length>(this.info.BuyBaseAccountCount-this.info.UsedBaseAccountCount)){
        this.$message.error('基础帐号剩余可使用数量不足')
        return
      }else if(this.activationmsg.LicenseAccountType==2&&this.managementCustomer.length>(this.info.BuyContactAccountCount-this.info.UsedContactAccountCount)){
        this.$message.error('互通帐号剩余可使用数量不足')
        return
      }
      let errlist = []
      try{
        this.managementLoading = true
        for(let item of this.managementCustomer){
          // console.log(item)
          let data = JSON.parse(JSON.stringify(this.activationmsg))
          data.EmployeeId = item.Id
          data.noError = true
          let errormsg = {
            Id:item.Id,
            WxHeadUrl:item.WxHeadUrl,
            EmployeeName:item.EmployeeName,
            WxNickname:item.WxNickname,
            Phone:item.Phone,
            message:''
          }
          let res = await qyweixinactiveaccount(data)
          if(!res.IsSuccess){
            errormsg.message = res.Message
            errlist.push(errormsg)
          }
        }
      }finally{
        if(errlist&&errlist.length){
          // console.log(errlist)
          this.errShow = true
          this.errlist = errlist
        }else{
          this.activationShow = false
          this.$message.success('操作成功')
        }
        this.getinfo()
        this.gettablelist()
        this.managementLoading = false
      }
    },
    close(){
      this.activationmsg = {
        LicenseAccountType:0,
        EmployeeId:''
      }
      this.managementCustomer = []
      this.searchkey = ''
      this.searchloading = false
      this.activationShow = false
    },
    clear(i){
      this.managementCustomer.splice(i,1)
    },
    async search(){
      if(!this.searchkey){
        this.$message.error('请输入手机号')
        return
      }else if(this.searchkey.length!=11){
        this.$message.error('请输入完整的11位手机号')
        return
      }else if(this.managementCustomer.some(v=>v.Phone==this.searchkey)){
        this.$message.error('该手机号已添加，请勿重复查询')
        return 
      }
      try{
        this.searchloading = true
        let res = await employeeemployeeinfobyphone({
          Phone:this.searchkey,
        })
        if(res.IsSuccess){
          if(res.Result.Id){
            this.managementCustomer.unshift({
              ...res.Result,
              Phone:this.searchkey
            })
          }else{
            this.$message.error('未找到对应员工数据，请检查手机号是否有误')
          }
        }
      }finally{
        this.searchloading = false
      }
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.pageSize = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let data = {
          Skip:(this.currentPage-1)*this.pageSize,
          Take:this.pageSize,
          KeyWords:this.keywords
        }
        let res = await qyweixinlicenseusedetail(data)
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },
    async getinfo(){
      try{
        this.infoloading = true
        let res = await qyweixinlicenseuseinfo()
        if(res.IsSuccess){
          this.info = res.Result
        }
      }finally{
        this.infoloading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .card-header{
    font-size: 20px;
    font-weight: 400;
    color: #303133;
    line-height: 28px;
  }
  .flex{
    display: flex;
  }
  .flex-align-center{
    align-items: center;
  }
  .flex-justify-between{
    justify-content: space-between;
  }
  .con-box{
    border: 1px solid #EBEEF5;
    border-radius: 2px;
    flex:1;
    .con-header{
      background: #F8F8F9;
      border-bottom: 1px solid #EBEEF5;
      padding: 17px 24px;
      color: #303133;
      line-height: 22px;
      font-size: 16px;
    }
    .con-content{
      display: flex;
      justify-content: space-between;
    }
    .demo-box{
      padding: 23px 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .number{
        color: #303133;
        font-size: 16px;
        line-height: 22px;
      }
      .font{
        font-size: 14px;
        color: #606266;
        font-weight: 400;
        line-height: 20px;
        margin-top: 12px;
      }
    }
  }
  .grayfont{
    font-size: 12px;
    color: #999999;
    line-height: 1.5;
  }
  .defaultbox{
    flex-wrap:wrap;
    overflow-y:auto;
    max-height:320px;
    padding-top:20px;
    min-height: 80px;
    .customerBox{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0px 20px 20px 0px;
      width: 100px;
      .imgbox{
        position: relative;
        top: 0px;
        left: 0px;
        img{
          width: 80px;height: 80px;
          border-radius: 2px;
        }
        .closeIcon{
          position: absolute;
          left: 70px;
          top: -10px;
          // border: 1px solid red;
          cursor: pointer;
          font-size: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .phone{
        font-size: 14px;
        color: #999999;
        line-height: 1.5;
        margin: 5px 0px;
      }
    }
  }
</style>